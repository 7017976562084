import React, { useState } from 'react';
import { Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Collapse, IconButton, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { FinanceInvoiceListTableProps, InvoiceDialogProps, patientInvoice } from "../types/Module_Type";
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { emptyInvoice, price, product, productName, quantity, RED_COLOR, total, view } from '../utils/constants';
import { get } from 'https';
import { VERSION } from '../constants';
import axios from 'axios';


type HeadCell = {
    disablePadding: boolean;
    id: keyof patientInvoice;
    label: string;
    numeric: boolean;
}

type invoiceTableProps = {
    numSelected: number;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    rowCount: number;
}

const FinanceInvoiceListTable: React.FC<FinanceInvoiceListTableProps> = ({
  invoices,
  handleSelectAllClick,
  setDateFormat,
  selectedInvoices,
  handleInvoiceListSelectAllClick,
  handleSelectedInvoicesChange,
  invoiceIds
}) => {

    const headCells: readonly HeadCell[] = [
        {
            id: 'invoice_name',
            numeric: false,
            disablePadding: true,
            label: 'Invoice number',
        },
        {
            id: 'invoice_date',
            numeric: false,
            disablePadding: false,
            label: 'Invoice date',
        },
        {
            id:'invoice_view',
            numeric:false,
            disablePadding:false,
            label:'Invoice View'
        }
    ];
    const currentUser = useSelector((state: RootState) => {
      return state.user;
    });
    function InvoiceTableHead(props: invoiceTableProps) {
        const { numSelected, rowCount } = props;
        const shouldDisplaySelectAll = invoices.some((invoice) => invoice.flag);

        if (!shouldDisplaySelectAll) {
            return null; // Do not render the TableHead when there are no invoices with flag set to false
        }
      
        return (
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                {currentUser.userRole === 'doctorReferralExecutive' &&(
                  <Checkbox
                    color="primary"
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={rowCount > 0 && numSelected === rowCount}
                    onChange={handleInvoiceListSelectAllClick}
                    inputProps={{
                      'aria-label': 'select all invoices',
                    }}
                  />
                )}
              </TableCell>
              <TableCell>&nbsp;</TableCell>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? 'right' : 'left'}
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                >
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        );
    }

    function InvoiceTableRow(props: { row: patientInvoice }) {
      const { row } = props;
      const [invoiceView_id , setInvoiceView_ID]= React.useState("");
      const [open, setOpen] = React.useState(false);
      const isSelected = (name: string) => selectedInvoices.indexOf(name) !== -1;
      const isItemSelected = isSelected(row?.invoice_name);
  
      const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
          const selectedIndex = selectedInvoices.indexOf(name);
          let newSelected: readonly string[] = [];
  
          if (selectedIndex === -1) {
              newSelected = [...selectedInvoices, name];
          } else if (selectedIndex === 0) {
              newSelected = selectedInvoices.slice(1);
          } else if (selectedIndex === selectedInvoices.length - 1) {
              newSelected = selectedInvoices.slice(0, -1);
          } else if (selectedIndex > 0) {
              newSelected = [
                  ...selectedInvoices.slice(0, selectedIndex),
                  ...selectedInvoices.slice(selectedIndex + 1),
              ];
          }
          handleSelectedInvoicesChange(newSelected);
      };
React.useEffect(() => {
  setInvoiceView_ID(row.invoice_id);
}, [row.invoice_id]); 

  function RedirectToViewInvoice(event: any) {
    const url = `https://dev.medunited.odoo.techsophy.com/report/pdf/ts_templates.template_invoice_a4/${invoiceView_id}`;
    window.open(url, '_blank', 'noopener,noreferrer')
  }

      return (
          <>
          {row.flag === true && invoiceIds.includes(row.invoice_name) ? (
                  <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row?.invoice_name}
                      sx={{ '& > *': { borderBottom: 'unset' } }}
                  >
                      <TableCell padding="checkbox">
                        {currentUser.userRole === 'doctorReferralExecutive' &&(
                          <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              onClick={(event) => handleClick(event, row?.invoice_name)}
                              inputProps={{
                                  'aria-labelledby': row?.invoice_name,
                              }}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                          <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => setOpen(!open)}
                          >
                              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                          </IconButton>
                      </TableCell>
                      <TableCell component="th" scope="row">
                          {row?.invoice_name}
                      </TableCell>
                      <TableCell>{setDateFormat(row?.invoice_date)}</TableCell>
                      <TableCell>
                        <button onClick={RedirectToViewInvoice}>{view}</button>
                      </TableCell>
                  </TableRow>
              ) : null}
              {row?.flag === true && (
                  <TableRow>
                      <TableCell className="invoices_box_products_block" colSpan={6}>
                          <Collapse in={open} timeout="auto" unmountOnExit>
                              <Box className="invoices_box_products_margin">
                                  <Typography variant="h6" gutterBottom component="div">
                                      {product}
                                  </Typography>
                                  <Table size="small" aria-label="purchases">
                                      <TableHead>
                                          <TableRow>
                                              <TableCell>{productName}</TableCell>
                                              <TableCell>{quantity}</TableCell>
                                              <TableCell align="right">{price}</TableCell>
                                              <TableCell align="right">{total}</TableCell>
                                          </TableRow>
                                      </TableHead>
                                      <TableBody>
                                          {row?.products.map((productsRow: any) => (
                                              <TableRow key={productsRow.product_name}>
                                                  <TableCell
                                                    component="th"
                                                    scope="row"
                                                    style={{
                                                      color: productsRow?.product_category?.toLowerCase() === 'pharmacy' ? RED_COLOR : '',
                                                    }}
                                                  >
                                                    {productsRow?.product_name}
                                                  </TableCell>
                                                  <TableCell 
                                                    component="th"
                                                    scope="row"
                                                    style={{
                                                      color: productsRow?.product_category?.toLowerCase() === 'pharmacy' ? RED_COLOR : '',
                                                    }}
                                                  >
                                                    {productsRow?.quantity}
                                                  </TableCell>
                                                  <TableCell
                                                    align="right"
                                                    style={{
                                                      color: productsRow?.product_category?.toLowerCase() === 'pharmacy' ? RED_COLOR : '',
                                                    }}
                                                  >
                                                    {productsRow?.price}
                                                  </TableCell>
                                                  <TableCell
                                                    align="right"
                                                    style={{
                                                      color: productsRow?.product_category?.toLowerCase() === 'pharmacy' ? RED_COLOR : '',
                                                    }}
                                                    >
                                                      {productsRow?.product_total}
                                                  </TableCell>
                                              </TableRow>
                                          ))}
                                      </TableBody>
                                  </Table>
                              </Box>
                          </Collapse>
                      </TableCell>
                  </TableRow>
              )}
          </>
      );
  }
  
  return (
    <TableContainer component={Paper} className="invoices_tableContainer">
      <Table stickyHeader aria-label="collapsible table">
        <InvoiceTableHead
          numSelected={selectedInvoices.length}
          onSelectAllClick={handleSelectAllClick}
          rowCount={invoices.length}
        />
        <TableBody>
          {invoices?.filter((invoice) => invoice.flag).length > 0 ? (
            invoices?.map((invoiceRecord) => (
              <InvoiceTableRow key={invoiceRecord?.invoice_name} row={invoiceRecord} />
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={6} align="center">
              {emptyInvoice}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );  
};

export default FinanceInvoiceListTable;